const baseUrl = "https://api.ediqia.com/api";

const URL = {
  LOGIN: `${baseUrl}/login-admin`,
  REGISTER: `${baseUrl}/createEmployee`,
  LIST_USER: `${baseUrl}/ListEmployee`,
  USER_UPDATE: `${baseUrl}/updateEmployee`,
  USER_DESTROY: `${baseUrl}/deleteEmployee`,
  USER_CONNECTE: `${baseUrl}/sys_user_connecte`,

  LIST_TICKET: `${baseUrl}/ticket_admin`,
  USER_TICKET: `${baseUrl}/ticket_user`,
  TICKET_STATUS: `${baseUrl}/ticket_status`,
  TICKET_DESTROY: `${baseUrl}/ticket_destroy`,
  SENDMESSAGE: `${baseUrl}/ticket_send_message`,

  // Blog
  lIST_CATEGORY_BLOG: `${baseUrl}/blog/category`,
  CREATED_BLOG: `${baseUrl}/blog_create`,
  UPDATE_BLOG: `${baseUrl}/blog/update`,
  DELETE_BLOG: `${baseUrl}/blog/destroy`,
  LIST_BLOG: `${baseUrl}/blog/list`,


  // Entreprise
  ENTREPRISE: `${baseUrl}/panel/entreprise`,
  PERMISSION_LIST: `${baseUrl}/permission`,
  ROLE_CREATE: `${baseUrl}/role/store`,
  ROLE_INDEX: `${baseUrl}/role`,
  ROLE_UPDATE: `${baseUrl}/role/update`,
  

  // Relance
  RELANCE_USER: `${baseUrl}/panel/relance_user`,
  RELANCE_ENTREPRISE: `${baseUrl}/panel/relance_entreprise`,

  // Newsletter
  NEWSLETTER_LIST: `${baseUrl}/panel/newsletter_list`,
  NEWSLETTER_SEND_MESSAGE: `${baseUrl}/panel/newsletter_send`,

  // Category
  CATEGORY_LIST: `${baseUrl}/panel/category_list`,
  CATEGORY_CREATED: `${baseUrl}/panel/category_create`,
  CATEGORY_UPDATE: `${baseUrl}/panel/category_update`,
  CATEGORY_DELETE: `${baseUrl}/panel/category_destroy`,

};

export default URL;
