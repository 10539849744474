<template>
   <section class="flex flex-col gap-4">
      <div class="grid grid-cols-3 gap-4">
         <div
            class="inline-flex col-span-1 bg-white rounded-md p-[16px] py-4 flex-col gap-8"
         >
            <span class="text-2xl text-center">Hello, {{user_connecte_name}} </span>

            <div v-for="(chif, index) in 1" :key="index">
               <div class="flex flex-col text-center gap-4">
                  <span
                     class="bg-purple-900 text-white font-light text-sm p-[4px] rounded-md"
                     >Chiffre d'affaire</span
                  >
                  <span class="text-3xl font-semibold"> {{state.turnover}}  Fcfa</span>
                  <span class="font-light text-sm"
                     >Ceci est le chiffre d'affaire de l'annee en cours</span
                  >
               </div>
            </div>
         </div>
         <div
            class="grid grid-cols-2 items-center bg-white rounded-md justify-center gap-16 col-span-2 p-3"
         >
            <div v-for="(compagny, index) in state.compagny" :key="index">
               <div
                  class="flex flex-col text-center items-center justify-center"
               >
                  <span class="text-4xl font-semibold"> {{compagny.data}} </span>
                  <span class=""> {{ compagny.title }} </span>
               </div>
            </div>
         </div>
      </div>

      <div class="grid grid-cols-3 gap-4">
         <div
            v-for="(user, index) in state.users"
            :key="index"
            class="inline-flex items-center justify-center gap-4 bg-white rounded-md py-2 p-1"
         >
            <div>
               <div
                  class="flex flex-col text-center items-center justify-center"
               >
                  <span class="text-4xl font-semibold">  {{user.data}} </span>
                  <span class=""> {{ user.title }} </span>
               </div>
            </div>
         </div>
      </div>

      <div class="grid grid-cols-2 gap-4">
         <div v-for="(list, index) in state.lists" :key="index">
            <div class=" bg-white rounded-md">
               <div
                  class="flex justify-center items-center bg-purple-900 p-[8px] text-white font-semibold rounded-t-md"
               >
                  {{ list.title }}
               </div>

               <div class="h-[360px] w-full overflow-y-auto py-[8px] px-2 ">
                 
                  <div class="flex justify-between items-center px-2 py-[16px]   " :class="list.data.length === index + 1 ? '' : 'border-b-[0.5px]'" v-for="(user, index) in list.data" :key="index">
                     
                     <div class="flex flex-col">
                        <span> {{user.nom}} {{user.prenoms}} </span>
                     <span> {{user.email ? user.email : 'No email defined'}} </span>
                     </div>

                     <button @click="relanceUserByEmail(user.email, index)" class="bg-purple-900 text-white p-[8px] text-xs font-bold rounded "> Relancer </button>

                  </div>

               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue';
import URL from '@/views/request';
import axios from 'axios';
import { onMounted, reactive, ref } from '@vue/composition-api';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
   components: {
      ToastificationContent,
      BCard,
      BCardText,
      BLink,
   },

   async mounted() {
      try {
         await axios.get(URL.USER_CONNECTE).then((response) => {
            this.user_connecte = response.data;
            this.user_connecte_name = response.data.user.nom
            console.log('user', this.user_connecte);
            localStorage.setItem('user', JSON.stringify(this.user_connecte));
         });
      } catch (error) {
         console.log(error);
      }
   },

   setup(props, {root}) {
      const user_connecte = ref({})
      const user_connecte_name = ref("")
      const state = reactive({
         turnover: 0,
         compagny: [
            {
               title: "Nombre d'entreprises",
               data: 0
            },
            {
               title: "Nombre d'utilisateurs n'ayant pas d'entreprise",
               data: 0
            },
         ],
         users: [
            {
               title: "Nombre d'utilisateurs",
               data: 0
            },
            {
               title: "Nombre d'utilisateurs Premium",
               data: 0
            },
            {
               title: "Nombre d'utilisateurs Gratuit",
               data: 0
            },
         ],
         lists: [
            {
               title: 'Utilisateurs - abonnement expire dans 3 jours',
               data: 0
            },
            {
               title: "Utilisateurs n'ayant pas d'entreprise",
               data: 0
            },
         ],
      });

      onMounted(()=> {
         getDataEntreprise()
      })



      const getDataEntreprise = async() => {

         try {

            const { data } = await axios.get(URL.ENTREPRISE)
            if(data){
               // Chiffre d'affaire
               state.turnover = data.chiffre_affaire_annee

               // Compagny number
               state.compagny[0].data = data.nombre_entreprise.length

               // User no Compagny number
               state.compagny[0].data = data.nombre_user_no_entreprise.length

                // User number
               state.users[0].data = data.nombre_user.length

                // User number premium
               state.users[1].data = data.nombre_entreprise_premium.length

                // User number free
               state.users[2].data = data.nombre_entreprise_gratuit.length

               // Lists
               state.lists[1].data = data.nombre_user_no_entreprise
            }
            
         } catch (error) {
            console.log(error)
         }

      }


      const relanceUserByEmail = async(userEmail, whoRelance) => {
         
         try {
            
            const { data } = await axios.post(whoRelance === 0 ? URL.RELANCE_ENTREPRISE :  URL.RELANCE_USER , {email: userEmail})
            if(data){
                root.$toast({
        component: ToastificationContent,
        props: {
          title: "Relance",
          icon: "BellIcon",
          text: "👋 Operation reussi avec success.",
          variant: "success",
        }
      });
               console.log(data)
            }


         } catch (error) {
            console.log(error)
         }

      }

      return {
         state,
         relanceUserByEmail,
         user_connecte,
         user_connecte_name
      };
   },
};
</script>

<style></style>
